import React from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = props => {
  if (process.env.REACT_APP_DISABLE_GOOGLE_FLOW === 'true') {
    return (
      <HCaptcha
        sitekey={process.env.REACT_APP_HCAPTCHA_KEY}
        onVerify={(token, ekey) => props.setCaptchaToken(token, ekey)}
        recaptchacompat={false}
      />
    );
  }
  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
      onChange={val => {
        props.setCaptchaToken(val);
      }}
    />
  );
};

export default Captcha;
