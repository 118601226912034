import React from 'react';
import ErrorPage from '../components/error/500';
import { datadogRum } from '@datadog/browser-rum';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });

    const renderingError = new Error(error.message);
    renderingError.name = 'ReactRenderingError';
    if (errorInfo.componentStack) {
      renderingError.stack = errorInfo.componentStack;
    }
    renderingError.cause = error;
    datadogRum.addError(renderingError, {
      type: error.name || 'UNKNOWN',
      errorType: 'CUSTOM',
      headers: {
        source_url: window.location.toString(),
      },
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
