import React from 'react';
import { Box, Grid, Link as MuiLink } from '@material-ui/core';
import Button from '../../designSystem/Button';
import Captcha from '../Captcha';
import DividerWithText from '../DividerWithText';
import GoogleSignInButton from '../../../images/google-btn-logo.png';
import HaveAlreadyAccount from '../../loginComponent/HaveAlreadyAccount';

const AuthenticationCTAs = ({
  onCaptchaClickHandler,
  onSignupButtonProps,
  onSigninButtonProps,
  onForgotPasswordClick,
  onResetPasswordButtonProps,
  enableDivider,
  onGoogleSignupButtonProps,
  onGoogleSigninButtonProps,
  bottomHelperText,
  signBottomHelper,
}) => {
  return (
    <>
      {onCaptchaClickHandler && (
        <Grid item xs={12}>
          <Box
            marginY={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
          <Captcha setCaptchaToken={onCaptchaClickHandler} />
          </Box>
        </Grid>
      )}
      {onSignupButtonProps && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            {...onSignupButtonProps}
          >
            Sign Up
          </Button>
        </Grid>
      )}
      {onSigninButtonProps && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            {...onSigninButtonProps}
          >
            Sign In
          </Button>
        </Grid>
      )}
      {onForgotPasswordClick && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <MuiLink
            href="#"
            onClick={onForgotPasswordClick}
            id="forgotPassword-CTA"
          >
            Forgot Password
          </MuiLink>
        </Grid>
      )}
      {onResetPasswordButtonProps && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            {...onResetPasswordButtonProps}
          >
            Reset Password
          </Button>
        </Grid>
      )}
      {enableDivider && process.env.REACT_APP_DISABLE_GOOGLE_FLOW !== 'true' && (
        <Grid item xs={12}>
          <DividerWithText text="Or" />
        </Grid>
      )}
      {onGoogleSignupButtonProps && process.env.REACT_APP_DISABLE_GOOGLE_FLOW !== 'true' &&  (
        <Grid item xs={12}>
          <Button
            startIcon={<img src={GoogleSignInButton} alt="google sign-up" />}
            variant="outlined"
            fullWidth
            {...onGoogleSignupButtonProps}
          >
            Sign up with Google
          </Button>
        </Grid>
      )}
      {onGoogleSigninButtonProps && process.env.REACT_APP_DISABLE_GOOGLE_FLOW !== 'true' && (
        <Grid item xs={12}>
          <Button
            startIcon={<img src={GoogleSignInButton} alt="google sign-in" />}
            variant="outlined"
            fullWidth
            {...onGoogleSigninButtonProps}
          >
            Sign in with Google
          </Button>
        </Grid>
      )}

      {signBottomHelper ? <HaveAlreadyAccount /> : <></>}

      {bottomHelperText && (
        <Grid item xs={12}>
          {bottomHelperText}
        </Grid>
      )}
    </>
  );
};

export default AuthenticationCTAs;
