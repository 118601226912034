import React, { useState } from 'react';
import * as _ from 'lodash';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOutUser,
} from '../firebase/helper';
import {
  signupWithEmailAndPasswordWithoutFireBase,
  successRedirectionCopy,
} from '../common/helper';
import { useDispatch } from 'react-redux';
import { payCreateUserMeta, ObjectHasKey } from '../../utils/generic';
import { ActionTypes } from '../modules/PayAuth/reducer';
import SigninForm from '../modules/Signin/SigninForm';
import TwoFactorVerify from './TwoFactorVerify';
import VerifyEmailBackDrop from './VerifyEmailBackdrop';
import EmailVerifiedBackdrop from './EmailVerifiedBackdrop';
import { resendVerifyEmail } from '../../api';
import { FIREBASE_ERROR_MESSAGES } from '../../utils/constants';
import { datadogRum } from '@datadog/browser-rum';

const SignIn = ({
  testingEnv,
  state,
  dispatch,
  onChangeHandler,
  onCaptchaClickHandler,
  ignoreCaptchaValidation,
  ...props
}) => {
  const _dispatch = useDispatch();

  const [twoFaRes, setTwoFaRes] = useState(null);
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');

  const [showVerifyEmailScreen, setShowVerifyEmailScreen] = useState(false);
  const [showDroppedOffScreen, setShowDroppedOfScreen] = useState(false);

  const handleSignInWithGoogle = async () => {
    _dispatch(startPageLoader());
    try {
      const authRes = await signInWithPopup('google.com');
      if (
        !_.isEmpty(props.inviteUser) &&
        authRes?.user?.email.toLowerCase() !==
          state.formData.email.toLowerCase()
      ) {
        throw new Error('Please sign in using your invite email address');
      }

      const meta = payCreateUserMeta({
        type: state.userType,
        role: state.userRole,
      });
      meta.googleSignIn = true;

      const res = await successRedirectionCopy(props, '', meta);

      datadogRum.setUser({
        id: res?.data?.authId,
      });
      if (res?.data?.isOnboardingCompleted === false) {
        setShowDroppedOfScreen(true);
      }

      if (res.data.code === '2FA_INCOMPLETE') {
        const user = authRes.user;
        if (!user) {
          throw new Error('user not found');
        }

        _dispatch(stopPageLoader());

        if (user.phoneNumber) {
          setNumber(user.phoneNumber);
        }

        if (user.email) {
          setEmail(user.email);
        }

        const twoFaRes = { ...res.data };

        delete twoFaRes.code;
        delete twoFaRes.message;

        setTwoFaRes(twoFaRes);
      } else {
        if (res?.data?.success) {
          await signOutUser();
          window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
        } else {
          _dispatch(stopPageLoader());
        }
      }
    } catch (error) {
      _dispatch(stopPageLoader());
      _dispatch(showSnackbar(error.message));
      datadogRum.addError(error, {
        errorType: 'CUSTOM',
      });
    }
  };

  const signupWithEmailAndPasswordNonFirebase = async () => {
    const res = await signupWithEmailAndPasswordWithoutFireBase(
      state.formData.email.toLowerCase(),
      state.formData.password,
      state.captchaToken
    );

    dispatch({
      type: ActionTypes.UpdateMetaObject,
      payload: res?.data?.meta,
    });

    _dispatch(stopPageLoader());

    return res;
  };
  const signupWithEmailAndPassword = async () => {
    const user = await signInWithEmailAndPassword(
      state.formData.email.toLowerCase(),
      state.formData.password
    );

    const meta = payCreateUserMeta({
      type: state.userType,
      role: state.userRole,
    });
    const res = await successRedirectionCopy(
      props,
      '',
      meta,
      state.captchaToken
    );
    dispatch({
      type: ActionTypes.UpdateMetaObject,
      payload: res?.data?.meta,
    });

    _dispatch(stopPageLoader());

    return { res, user };
  };

  const handleSigninWithEmailAndPassword = async e => {
    e.preventDefault();
    if (!ObjectHasKey(state.errorData)) {
      _dispatch(startPageLoader());
      try {
        if (process.env.REACT_APP_DISABLE_GOOGLE_FLOW === 'true') {
          const res = await signupWithEmailAndPasswordNonFirebase();
          console.log({ res });
          if (res?.data?.success) {
            await signOutUser();
            window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
          } else {
            _dispatch(stopPageLoader());
          }
          return;
        }
        const { res, user } = await signupWithEmailAndPassword();

        datadogRum.setUser({
          id: res?.data?.authId,
        });
        if (res?.data?.emailVerified === false) {
          setShowVerifyEmailScreen(true);
          return;
        }
        if (res?.data?.isOnboardingCompleted === false) {
          setShowDroppedOfScreen(true);
          return;
        }

        if (res.data.code === '2FA_INCOMPLETE') {
          if (!user) {
            throw new Error('user not found');
          }

          _dispatch(stopPageLoader());

          if (user.phoneNumber) {
            setNumber(user.phoneNumber);
          }
          if (user.email) {
            setEmail(user.email);
          }
          const twoFaRes = { ...res.data };

          delete twoFaRes.code;
          delete twoFaRes.message;

          setTwoFaRes(twoFaRes);
        } else {
          if (res?.data?.success) {
            await signOutUser();
            window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
          } else {
            _dispatch(stopPageLoader());
          }
        }
      } catch (error) {
        _dispatch(showSnackbar(error.message));
        _dispatch(stopPageLoader());
        datadogRum.addError(error, {
          errorType: 'CUSTOM',
        });
      }
    }
  };

  if (showVerifyEmailScreen) {
    return (
      <VerifyEmailBackDrop
        email={state.formData.email}
        onResend={() => {
          _dispatch(startPageLoader());
          resendVerifyEmail(
            state.formData.email,
            props.redirectURL,
            !state.formData.companyName
          )
            .then(() => {
              _dispatch(showSnackbar('Verification email resent successfully'));
              _dispatch(stopPageLoader());
            })
            .catch(error => {
              console.log({ error });
              _dispatch(
                showSnackbar(
                  FIREBASE_ERROR_MESSAGES[error.response.data?.code] ||
                    error.response.data?.message ||
                    error.message
                )
              );
              _dispatch(stopPageLoader());
            });
        }}
      />
    );
  }
  if (showDroppedOffScreen) {
    return (
      <EmailVerifiedBackdrop
        onContinueClick={() => {
          window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
        }}
        btnText="Continue Setup"
        heading="Welcome Back!"
        subHeading="It looks like you’ve already verified your email. Let's continue setting up your profile to get the most out of Skuad."
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="104"
            fill="none"
            viewBox="0 0 130 104"
          >
            <path fill="#fff" d="M0 0H130V104H0z"></path>
            <path
              fill="#EFFAF7"
              fillRule="evenodd"
              d="M32.76 69.681h47.32c.268 0 .529-.029.78-.084.251.055.512.084.78.084h27.04a3.64 3.64 0 000-7.28h-3.12a3.64 3.64 0 010-7.28h9.88a3.64 3.64 0 000-7.28H104a3.64 3.64 0 000-7.28H70.72a3.64 3.64 0 100-7.28H41.08a3.64 3.64 0 000 7.28h-20.8a3.64 3.64 0 100 7.28h13a3.64 3.64 0 110 7.28h-20.8a3.64 3.64 0 100 7.28h20.28a3.64 3.64 0 100 7.28zm84.76 0a3.64 3.64 0 100-7.28 3.64 3.64 0 000 7.28z"
              clipRule="evenodd"
            ></path>
            <path
              stroke="#EFFAF7"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
              d="M89.7 23.398v3.12M89.7 30.68v3.12M84.5 28.6h3.12M91.78 28.6h3.12M46.54 76.96v2.08M46.54 83.2v2.08M42.38 81.123h2.08M48.62 81.123h2.08"
            ></path>
            <path
              fill="#EFFAF7"
              d="M86.84 54.4a25.6 25.6 0 01-.447.004c-13.132 0-23.884-10.238-24.785-23.205C51.155 32.848 43.16 41.94 43.16 52.906c0 12.135 9.79 21.973 21.865 21.973 11.576 0 21.05-9.04 21.815-20.479z"
            ></path>
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M57.628 33.494c-1.014.38-2.004.843-2.961 1.387a21.025 21.025 0 00-5.665 4.722m-1.43 1.912a20.655 20.655 0 00-1.65 3.011"
              clipRule="evenodd"
            ></path>
            <path
              fill="#EFFAF7"
              d="M58.067 34.664a1.25 1.25 0 10-.878-2.34l.878 2.34zm-3.4.217l.618 1.087-.618-1.087zm-4.18 3.133l-.869-.9.869.9zm-2.441.784a1.25 1.25 0 001.912 1.61l-1.912-1.61zm.569 3.407a1.25 1.25 0 10-2.085-1.38l2.084 1.38zm-2.057 1.015l1.103.587-1.103-.587zm-1.779.798a1.25 1.25 0 102.284 1.016l-2.284-1.016zm12.41-11.694a21.93 21.93 0 00-3.14 1.47l1.236 2.174c.899-.512 1.83-.946 2.782-1.304l-.878-2.34zm-3.14 1.47a22.112 22.112 0 00-4.43 3.32l1.736 1.799a19.611 19.611 0 013.93-2.945l-1.236-2.173zm-4.43 3.32a22.328 22.328 0 00-1.573 1.684l1.912 1.61a19.8 19.8 0 011.397-1.495l-1.737-1.798zm-3.09 3.711c-.389.589-.748 1.192-1.075 1.809l2.207 1.173c.29-.546.608-1.08.953-1.602l-2.084-1.38zm-1.075 1.809c-.242.455-.467.916-.675 1.384l2.284 1.016c.185-.414.384-.824.598-1.227l-2.207-1.173z"
            ></path>
            <path
              fill="#13B68F"
              d="M78.375 28a6.25 6.25 0 016.25 6.25v11.774a6.221 6.221 0 00-3.125-.837V34.25a3.125 3.125 0 00-2.76-3.104l-.365-.021H50.25a3.126 3.126 0 00-3.104 2.76l-.021.365v37.5a3.126 3.126 0 002.76 3.104l.365.021h12.5c0 1.726 1.4 3.125 3.125 3.125H50.25A6.25 6.25 0 0144 71.75v-37.5A6.25 6.25 0 0150.25 28h28.125z"
            ></path>
            <path
              fill="#13B68F"
              d="M54.938 40.5a1.563 1.563 0 000 3.125h12.5a1.563 1.563 0 000-3.125h-12.5zM53.375 51.438c0-.863.7-1.563 1.563-1.563h7.291a1.563 1.563 0 010 3.125h-7.291c-.863 0-1.563-.7-1.563-1.563zM54.938 59.25a1.563 1.563 0 100 3.125 1.563 1.563 0 000-3.125z"
            ></path>
            <path
              fill="#13B68F"
              fillRule="evenodd"
              d="M87.75 54.563a6.25 6.25 0 11-12.5 0 6.25 6.25 0 0112.5 0zm-3.125 0a3.125 3.125 0 11-6.25 0 3.125 3.125 0 016.25 0z"
              clipRule="evenodd"
            ></path>
            <path
              fill="#13B68F"
              d="M81.5 65.5c-2.55 0-4.9 1.192-6.626 3.205a1.563 1.563 0 01-2.372-2.035c2.244-2.616 5.426-4.295 8.997-4.295C88.593 62.375 94 68.873 94 76.438V78H81.5a1.563 1.563 0 010-3.125h9.282c-.66-5.448-4.72-9.375-9.283-9.375z"
            ></path>
          </svg>
        }
      />
    );
  }

  if (twoFaRes) {
    return (
      <TwoFactorVerify
        number={number}
        email={email}
        state={state}
        dispatch={dispatch}
        {...props}
        {...twoFaRes}
        setTwoFaRes={setTwoFaRes}
      />
    );
  }

  return (
    <SigninForm
      state={state}
      onChangeHandler={onChangeHandler}
      onSubmit={handleSigninWithEmailAndPassword}
      onSigninButtonProps={{
        disabled:
          !state.formData.email ||
          !state.formData.password ||
          !(ignoreCaptchaValidation || state.captchaToken) ||
          !!ObjectHasKey(state.errorData),
        id: 'form-signIn-submitCTA',
      }}
      onGoogleSigninButtonProps={{
        onClick: handleSignInWithGoogle,
        id: 'form-signIn-signInWithGoogleCTA',
      }}
      onCaptchaClickHandler={onCaptchaClickHandler}
    />
  );
};

export default SignIn;
