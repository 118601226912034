import React, { useRef } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CardWithTitle from '../designSystem/CardWithTitle';
import StandardTextField from '../designSystem/StandardTextField';
import { signInWithNumber, signOutUser } from '../firebase/helper';
import { payCreateUserMeta } from '../../utils/generic';
import { successRedirectionCopy } from '../common/helper';
import { ActionTypes } from '../modules/PayAuth/reducer';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import firebase from '../../components/firebase';
import { datadogRum } from '@datadog/browser-rum';

function maskPhoneNumber(phoneNumber) {
  // Convert the phone number to a string if it's not already
  phoneNumber = phoneNumber.toString();

  // Define the parts of the phone number
  const start = phoneNumber.slice(0, 3);
  const end = phoneNumber.slice(-2);

  // Create the masked phone number
  const masked = `${start}*****${end}`;

  return masked;
}

const OtpForm = ({
  classes,
  number,
  orgNumber,
  otp,
  setOtp,
  dispatch,
  state,
  _dispatch,
  setShowOtp,
  handleAuthChange,
  ...props
}) => {
  const captchaRef = useRef();

  const getOtp = recaptcha => {
    _dispatch(startPageLoader());

    signInWithNumber(number, recaptcha)
      .then(res => {
        if (res) {
          setShowOtp(true);
          _dispatch(stopPageLoader());
        }
      })
      .catch(error => {
        _dispatch(stopPageLoader());
        _dispatch(showSnackbar(error.message));
        datadogRum.addError(error, {
          errorType: 'CUSTOM',
        });
      });
  };

  return (
    <CardWithTitle
      title={
        props.phone_verified
          ? 'This helps us keep your account secure by verifying that it’s really you.'
          : 'Setup Two-Factor Authentication (2FA)'
      }
    >
      <div id="recaptcha-container"></div>
      <Typography variant="body2" className={classes.instructionText}>
        To make sure everything works, enter the 6 digit secure code you’ve
        received on {maskPhoneNumber(number)}
        {!props.phone_verified && (
          <>
            <br />
            Need to change the phone number?{' '}
            <Typography
              variant="inherit"
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!orgNumber) {
                  setShowOtp(false);
                }
              }}
            >
              Click here
            </Typography>
          </>
        )}
      </Typography>
      <form
        onSubmit={async e => {
          try {
            e.preventDefault();
            _dispatch(startPageLoader());
            await window.confirmationResult.confirm(otp);

            const meta = payCreateUserMeta(
              { type: state.userType, role: state.userRole },
              {
                newsLetterSubscription:
                  !!state.formData.newsLetterSubscription.length,
                companyName: state.formData.companyName,
              }
            );

            const res = await successRedirectionCopy(
              props,
              '',
              meta,
              state.captchaToken
            );

            dispatch({
              type: ActionTypes.UpdateMetaObject,
              payload: res?.data?.meta,
            });

            if (res?.data?.success) {
              await signOutUser();
              window.location.replace(
                `${decodeURIComponent(props.redirectURL)}`
              );
            } else {
              _dispatch(stopPageLoader());
            }
          } catch (error) {
            _dispatch(
              showSnackbar(error?.response?.data?.message || error.message)
            );
            _dispatch(stopPageLoader());
          }
        }}
      >
        <StandardTextField
          gridLayout={false}
          placeholder="6 digit secure code"
          helperText={
            <Box display="flex" width="100%" justifyContent="space-between">
              <p></p>{' '}
              <Typography
                color="primary"
                onClick={() => {
                  const recaptcha = new firebase.auth.RecaptchaVerifier(
                    'recaptcha-container',
                    { size: 'invisible' }
                  );
                  recaptcha.verify().then(() => getOtp(recaptcha));
                }}
                style={{ cursor: 'pointer' }}
              >
                Resend Code
              </Typography>
            </Box>
          }
          value={otp}
          onChange={e => {
            if (e.target.value.length > 6) {
              return;
            }
            setOtp(e.target.value);
          }}
        />

        <Box className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              handleAuthChange(!props.isGoogle ? '' : 'totp');
            }}
          >
            {!props.isGoogle
              ? 'Try Another Way'
              : props?.totp
              ? 'Use Authenticator'
              : 'Setup Authenticator App'}
          </Button>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              className={classes.backButton}
              onClick={() => {
                setShowOtp(false);
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={otp.length !== 6}
            >
              {props.phone_verified ? 'Verify Code' : 'Enable'}
            </Button>
          </Box>
        </Box>
      </form>
    </CardWithTitle>
  );
};

export default OtpForm;
