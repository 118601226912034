import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

import { datadogRum } from '@datadog/browser-rum';

const datadogConfig = JSON.parse(process.env.REACT_APP_DATADOG_CONFIG);
datadogRum.init({
  ...datadogConfig,
  proxy: options =>
    `${process.env.REACT_APP_DATADOG_PROXY_SITE}/${options.path}?${
      options.parameters
    }`,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
